<template>
  <div class="cookie-page">
    <section class="cookie-page__info">
      <h1>Bruk av Informasjonskapsler (Cookie-erklæring)</h1>
      <p class="cookie-page__info-padding-bottom">
        <strong>Sist oppdatert 23. desember 2021</strong>
      </p>

      <p>
        <strong
          >Denne cookie-erklæring gir deg informasjon om hva en informasjonskapsel er, hva vi bruker
          dem til og om hvordan du kan administrere dem i nettleserens innstillinger.</strong
        >
      </p>

      <h2>Informasjonskapsler (cookies) og personvern</h2>

      <p>
        <u><strong>Drammen Helsepark AS</strong></u> ved daglig leder er behandlingsansvarlig for
        innhenting og behandling av personopplysninger i forbindelse med drift og vedlikehold av vår
        nettside
        <a href="https://drammenhelsepark.no"
          ><u><strong>drammenhelsepark.no</strong></u></a
        >
        <u><strong>.</strong></u>
      </p>

      <p>
        Formålet med innsamlingen av personopplysninger via nettsiden er å loggføre bruken av vår
        nettside for å utvikle og forbedre tjenesten. Til dette bruker nettsiden informasjonskapsler
        ( såkalte "cookies"). Cookies er små tekstfiler som blir lagret på din datamaskin,
        mobiltelefon, nettbrett eller tilsvarende. Informasjonskapslene inneholder data som blir
        samlet inn for statistikk om bruk av
        <u><strong>drammenhelsepark.no</strong></u
        >.
      </p>

      <p>Når du bruker vår nettside blir det det samlet inn følgende opplysninger om deg:</p>

      <ul>
        <li>Nettleser og operativsystem</li>
        <li>Tidspunkt for besøk</li>
        <li>
          Hvilke sider (under vårt domene) den registrerte enheten besøker og varigheten på de ulike
          besøkene
        </li>
        <li>Eventuelt hvilken lenke som henviste deg hit</li>
        <li>
          Hva det ble klikket på i løpet av besøket ditt på nettsiden og eventuelle filer du lastet
          ned
        </li>
        <li>
          Opplysninger om deg når du registrerer personopplysninger på nettsiden, slik som navn,
          telefonnummer, e-post og arbeidssted.
        </li>
      </ul>

      <p>
        Vi anvender ulike typer lokal lagring gjennom bruk av cookies, som enten slettes fortløpende
        (sesjonscookies) eller som lagres permanent i din nettleser inntil et nærmere fastsatt
        tidspunkt (permanente cookies). Du finner mer informasjon om hvordan du kan kontrollere bruk
        av cookies i din nettleser nedenfor her.
      </p>

      <h2>Samtykke til bruk av cookies</h2>

      <p>
        De fleste nettlesere er innstilt slik at cookies aktiveres automatisk. Dersom dine
        innstillinger i din nettleser er satt til å akseptere cookies, samtykker du til at
        <u><strong>drammenhelsepark.no</strong></u>
        bruker cookies når du besøker våre nettsider.
      </p>

      <p>
        Du kan når som helst trekke tilbake samtykket ditt. Ved å endre innstillingene til
        nettleseren kan du deaktivere eller begrense bruken av cookies. Du finner ytterligere
        informasjon om hvordan du kan kontrollere bruk av cookies i din nettleser under punkt 3
        under.
      </p>

      <p>
        Dersom du deaktiverer cookies for våre nettsider, kan du ikke lenger bruke alle funksjonene
        på nettsidene, som for eksempel å abonnere på våre nyhetsbrev. For å kunne abonnere på
        nyhetsbrev må informasjonskapslene alltid være aktivert i nettleseren din.
      </p>

      <h2>
        Hvordan kan du administrere informasjonskapsler i nettleseren din?
      </h2>

      <p>
        Du kan selv endre og tilpasse innstillingene slik at du kun tillater de cookiene som du selv
        ønsker at skal være lagret i nettleseren din. Vær likevel oppmerksom på at nettleseren må
        akseptere enkelte cookies for at dette nettstedet skal fungere som forventet. Dette kan
        gjelde cookies fra en eller flere av følgende leverandører: Google Analytics, Vimeo,
        YouTube.
      </p>
      <p>
        I innstillingene til nettleseren din vil du vanligvis også kunne velge hvilke nettsider du
        tillater cookies fra. Du kan også be om å bli varslet hver gang en ny cookie blir lagret i
        nettleseren din.
      </p>

      <h3>Slik går du frem for å slette cookies i de ulike nettlesere</h3>
      <p>
        De følgende lenkene vil gi deg informasjon om hvordan du kan endre innstillingene i din
        nettleser for å ha kontroll på hvilke cookies den lagrer.
      </p>

      <ul>
        <li>
          <a
            href="http://windows.microsoft.com/en-GB/internet-explorer/delete-manage-cookies#ie=ie-10"
            >Cookie-innstillinger i Internet Explorer</a
          >
        </li>
        <li>
          <a href="http://support.mozilla.com/en-US/kb/Cookies">Cookie-innstillinger in Firefox</a>
        </li>
        <li>
          <a href="https://support.google.com/chrome/answer/95647?hl=en&amp;ref_topic=14666"
            >Cookie-innstillinger i Google Chrome</a
          >
        </li>
        <li>
          <a href="https://support.apple.com/kb/PH17191?locale=en_US"
            >Cookie-innstillinger i Safari web</a
          >
          og <a href="http://support.apple.com/kb/HT1677">iOS</a>.
        </li>
      </ul>
    </section>

    <hr />

    <section class="cookie-page__cookies">
      <h1>Cookies som benyttes på nettsidene våre</h1>

      <h3>Registrering</h3>

      <div class="table-overflow">
        <table cellpadding="0" cellspacing="0">
          <thead>
            <tr>
              <th>Cookie Name</th>
              <th>Provider</th>
              <th class="cookie-page__cookies--description">Description</th>
              <th>Duration</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>vuid</td>
              <td>Vimeo</td>
              <td class="cookie-page__cookies--description">
                These cookies are used by the Vimeo video player on websites.
              </td>
              <td>Persistent</td>
            </tr>
            <tr>
              <td>YSC, GPS, VISITOR_INFO1_LIVE, CONSENT</td>
              <td>Youtube / Google</td>
              <td class="cookie-page__cookies--description">
                YouTube is a Google owned platform for hosting and sharing videos. YouTube collects
                user data through videos embedded in websites, which is aggregated with profile data
                from other Google services in order to display targeted advertising to web visitors
                across a broad range of their own and other websites.
              </td>
              <td>Persistent</td>
            </tr>
            <tr>
              <td>eupubconsent</td>
              <td>IAB - International Advertising Bureau</td>
              <td class="cookie-page__cookies--description">
                This cookie is used by the IAB Europe Transparency & Consent Framework to store the
                user's consent to the data collection Purposes. The cookie holds an encrypted
                consent string that vendors participating in the framework can read and determine
                the user's consent.
              </td>
              <td>Persistent</td>
            </tr>
          </tbody>
        </table>
      </div>
    </section>
    <section class="cookie-page__drift">
      <h3>Drift av og funksjonalitet knyttet til nettsiden</h3>
      <div class="table-overflow">
        <table cellpadding="0" cellspacing="0">
          <thead>
            <tr>
              <th>Cookie Name</th>
              <th>Provider</th>
              <th class="cookie-page__drift--description">Description</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>_ga, _gid, _gat_gtag_*,_gat_UA-*</td>
              <td>Google Analytics</td>
              <td class="cookie-page__drift--description">
                A web analytics service by Google that provides statistics and basic analytical
                tools for optimization & marketing purpose
              </td>
            </tr>
            <tr>
              <td>_schn</td>
              <td>Adobe Analytics</td>
              <td class="cookie-page__drift--description">
                A web analytics service by Adobe that provides reporting, visualizations, and
                analysis of Customer Data that allows Customers to discover actionable insights
              </td>
            </tr>
            <tr>
              <td>
                _hjid, _hjFirstSeen, _hjIncludedInPageviewSample, _hjAbsoluteSessionInProgress
              </td>
              <td>Hotjar</td>
              <td class="cookie-page__drift--description">
                This cookie is used to generate website heatmaps & allow behavior analysis
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </section>
    <section class="cookie-page__tracking">
      <h3>Sporing og annonsering</h3>

      <div class="table-overflow">
        <table cellpadding="0" cellspacing="0">
          <thead>
            <tr>
              <th>Cookie Name</th>
              <th>Provider</th>
              <th class="cookie-page__tracking--description">Description</th>
              <th>Duration</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>_fbp, ATN</td>
              <td>Facebook</td>
              <td class="cookie-page__tracking--description">
                Vi bruker informasjonskapsler og lignende teknologi for å vise annonser på og
                utenfor Facebooks tjenester. Finn ut mer om hvordan Facebook bestemmer hvilke
                annonser du får se her: https://www.facebook.com/help/562973647153813
              </td>
              <td>Persistent</td>
            </tr>
            <tr>
              <td>_gclxxxx</td>
              <td>Google</td>
              <td>
                Google conversion tracking cookie
              </td>
              <td>Persistent</td>
            </tr>
            <tr>
              <td>sc_at, _scid</td>
              <td>Snapchat</td>
              <td class="cookie-page__tracking--description">
                Cookie associated with Snapchat. Sets a unique ID for the visitor, that allows third
                party advertisers to target the visitor with relevant advertisement. This pairing
                service is provided by third party advertisement hubs, which facilitates realtime
                bidding for advertisers.
              </td>
              <td>Persistent</td>
            </tr>

            <tr>
              <td>uid, C</td>
              <td>Adform</td>
              <td class="cookie-page__tracking--description">
                This domain is owned by Adform. The main business activity is: Real time bidding for
                display advertising to targeted audiences.
              </td>
              <td>Persistent</td>
            </tr>

            <tr>
              <td>MUID</td>
              <td>Bing / Microsoft</td>
              <td class="cookie-page__tracking--description">
                This domain is owned by Mircosoft - it is the site for the search engine Bing.
              </td>
              <td>Persistent</td>
            </tr>

            <tr>
              <td>mc</td>
              <td>Quantcast</td>
              <td class="cookie-page__tracking--description">
                This domain is owned by Quantcast. The main business activity is: Market and
                Audience Segmentation, Targeted advertising services
              </td>
              <td>Persistent</td>
            </tr>

            <tr>
              <td>guid, guid2</td>
              <td>
                Delta Projects <br />
                / de17a.com
              </td>
              <td class="cookie-page__tracking--description">
                This domain is owned by Delta Projects. The main business activity is: Ad Serving
                and Tracking Platform
              </td>
              <td>Persistent</td>
            </tr>
          </tbody>
        </table>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: 'Cookie-erklæring',
    }
  },
}
</script>

<style lang="scss">
.cookie-page {
  padding-block: 3rem;

  background-color: var(--primary);
  color: $beige;

  hr {
    max-width: 37.5rem;
  }

  ul li,
  strong,
  h1,
  h2,
  h3,
  p {
    word-break: break-word;
  }

  .table-overflow {
    display: block;
    width: 100%;

    overflow-x: auto;
  }

  &__tracking {
    table {
      @include respond-above('tablet') {
        width: 860px !important;
        margin: 0 auto;
      }
    }

    &--description {
      max-width: 400px;
    }
  }

  &__drift {
    &--description {
      max-width: 30%;
    }
  }
  &__cookies {
    padding-top: 3rem;

    &--description {
      max-width: 50%;
    }
  }

  &__tracking,
  &__drift,
  &__cookies {
    width: 100%;

    h3,
    h1 {
      font-weight: normal;
      text-align: center;
      margin-bottom: 2rem;
    }

    padding: 2rem;

    @include respond-below('tablet') {
      padding: 2rem 0;
    }

    table {
      min-width: 1000px;

      border-collapse: collapse;

      table-layout: auto;
      width: 100%;

      background: white;
      color: black;
      // padding: 40px 20px;

      // margin-left: auto;
      // margin-right: auto;

      border-radius: 8px;
      td,
      th {
        padding: 10px 12px;
        text-align: left;
        vertical-align: top;
      }

      td {
        border-top: 1px solid rgba(0, 0, 0, 0.15);
      }
      tr:first-child {
        td {
          border-top: 1px solid transparent;
        }
      }
    }
  }

  &__info {
    padding-top: 120px;
    padding-bottom: 2rem;
    max-width: 37.5rem;

    @include respond-below('tablet') {
      padding-inline: 1.5rem;
    }

    margin: 0 auto;
    ul {
      padding: 0 0 0 2.5rem;
    }
    h1 {
      font-weight: normal;
    }
    a {
      color: #00e;
    }

    &-padding-bottom {
      padding-bottom: 1.5rem;
    }
  }
}
</style>
